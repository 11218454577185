import { Stack } from '@mui/material';
import { Marker, MARKER_END_SECOND_OFFSET } from './Marker';
import { FC } from 'react';
import { calculateOverlapPercentage } from './utils';
import { useVideoPlayerContext } from 'contexts/VideoPlayerContext';
import { Seeker } from './Seeker';
import { Bar } from './Bar';
import { PatientFileRO } from 'api/queries/patient/useGetFile';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createMarkerParam } from 'components/PatientReview';

interface InteractiveTimelineProps {
  file: Pick<PatientFileRO, 'markers' | 'lengthInSeconds'>;
  totalDuration: number;
  startTime?: number;
  fileDuration: number;
}

/**
 * The overlap method checks the length of the overlap, since seeker doesn't really have a start and end
 * it's just a number to make the seeker has some sort of width that can be tracked
 */
const SEEKER_WIDTH = 0.1;

export const InteractiveTimeline: FC<InteractiveTimelineProps> = ({
  file,
  totalDuration,
  startTime = 0,
  fileDuration,
}) => {
  const navigation = useNavigate();
  const { videoPlayerRef } = useVideoPlayerContext();
  const currentTime = videoPlayerRef?.current?.getCurrentTime() || 0;

  const [searchParams] = useSearchParams();
  const activeMarkerId = searchParams.get('markerId') || '';

  const seekerPosition = calculateOverlapPercentage({
    timeline: { start: startTime, end: startTime + totalDuration },
    point: { start: currentTime, end: currentTime + SEEKER_WIDTH },
  });

  const getFileWidth = (fileDuration: number) => {
    const percentage = (fileDuration / totalDuration) * 100;
    return percentage + '%';
  };

  const pickMarker = (event: React.MouseEvent<HTMLDivElement>) => {
    const { target } = event;
    const targetId = (target as HTMLElement).id;

    if (!targetId) {
      return;
    }
    navigation({ search: createMarkerParam(targetId) });
  };

  const handleSeek = (event: React.MouseEvent<HTMLDivElement>) => {
    const { currentTarget, clientX } = event;
    const barWidth = currentTarget.offsetWidth;
    const clickX = clientX - currentTarget.getBoundingClientRect().left;
    const percentageValue = (clickX / barWidth) * 100;

    const clickedTimeWithinTimeline = (percentageValue / 100) * totalDuration;
    const videoTime = startTime + clickedTimeWithinTimeline;
    videoPlayerRef?.current?.seekTo(videoTime, 'seconds');
    pickMarker(event);
  };

  return (
    <Stack direction="row" gap="2px">
      <Bar onClick={handleSeek} width={getFileWidth(totalDuration)}>
        {seekerPosition && <Seeker position={`${seekerPosition.overlapStartPercentage}%`} />}
        {file.markers.map((marker) => {
          const isMarkerVisible = calculateOverlapPercentage({
            timeline: { start: startTime, end: startTime + fileDuration },
            point: {
              start: marker.startSecond,
              end: (marker.endSecond || file.lengthInSeconds) + MARKER_END_SECOND_OFFSET,
            },
          });

          if (!isMarkerVisible) {
            return null;
          }

          return (
            <Marker
              isActive={activeMarkerId === marker.id}
              marker={marker}
              startTime={startTime}
              fileDuration={fileDuration}
              key={marker.id}
            />
          );
        })}
      </Bar>
    </Stack>
  );
};
