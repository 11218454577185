import { Stack, Typography } from '@mui/material';
import { COLORS } from '../../constants';
import { formatTime, getStepSize } from './utils';

interface TimeStampsProps {
  totalFilesDuration: number;
}

// The additional 1px makes the Timestamp's indicator be closer to "pixel perfect" design in alignment to the Timeline
const CONTAINER_OFFSET = '1px';

export const Timestamps = ({ totalFilesDuration }: TimeStampsProps) => {
  const { stepSize, numberOfSteps } = getStepSize(totalFilesDuration);

  const timestamps = Array.from({ length: numberOfSteps }, (_, i) => {
    const label = formatTime(stepSize * i, totalFilesDuration);
    return {
      label,
      percentageOfTotal: ((stepSize * i) / totalFilesDuration) * 100,
    };
  }).concat({
    label: formatTime(totalFilesDuration, totalFilesDuration),
    percentageOfTotal: 100,
  });

  return (
    <Stack
      direction="row"
      width={`calc(100% - ${CONTAINER_OFFSET})`}
      position="relative"
      mt="10px"
      sx={{
        left: CONTAINER_OFFSET,
        height: '20px',
        '.MuiTypography-root::before': {
          content: "''",
          width: '1px',
          height: '5px',
          bgcolor: COLORS.GRAY_PRIMARY,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '100%',
        },
      }}
    >
      {timestamps.map((timestamp) => (
        <Typography
          key={timestamp.label}
          variant="P3_B"
          fontWeight={400}
          color={COLORS.GRAY_PRIMARY}
          position="absolute"
          left={`${timestamp.percentageOfTotal}%`}
          sx={{ transform: 'translateX(-50%)' }}
        >
          {timestamp.label}
        </Typography>
      ))}
    </Stack>
  );
};
