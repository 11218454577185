import { Box, Button, Stack, Typography } from '@mui/material';
import { PatientVideo } from '../components/PatientVideo';
import { PatientReview } from '../components/PatientReview';
import { useGetPatientFile } from '../api/queries/patient/useGetFile';
import { PatientReviewResult } from '../components/PatientReviewResult';
import { COLORS } from '../constants';
import { useTranslation } from 'react-i18next';
import { useFileParams } from 'hooks/useFileParams';
import { usePatientFiles } from 'api/queries/patient/usePatientFiles';
import { REVIEW_STATUS } from 'contexts/PatientListProvider';
import { useMarkerDetails } from 'hooks/useMarkerDetails';

export const Patient = () => {
  const { patientId, selectedFileId } = useFileParams();
  const { t } = useTranslation();
  const { data: patientFile } = useGetPatientFile({ patientId, fileId: selectedFileId });
  const { activeMarker } = useMarkerDetails();

  const { data: files } = usePatientFiles({ patientId });
  const file = files?.files.find((file) => file.fileId === selectedFileId);
  const isReviewed = file?.fileReviewStatus === REVIEW_STATUS.REVIEWED;

  const name = files?.caseName
    ? files?.caseName
    : `${files?.patientFirstName} ${files?.patientLastName}`;

  return (
    <Box sx={{ mt: '42px', mb: 3, flex: 1 }}>
      {patientFile && file && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h1" sx={{ fontWeight: '600' }}>
              {name}
            </Typography>

            <Button
              variant="contained"
              sx={{ bgcolor: COLORS.PRIMARY, color: COLORS.BLACK_PRIMARY }}
              href={`/dashboard/patient/${patientId}`}
            >
              {t('patient.goToCalendar')}
            </Button>
          </Stack>
          <Box sx={{ display: 'flex', gap: 3, pt: 2, pb: 3, flexWrap: 'wrap' }}>
            <PatientVideo key={selectedFileId} />
          </Box>
          {isReviewed ? (
            <PatientReviewResult />
          ) : (
            <PatientReview key={`${patientFile?.fileUrl}/${activeMarker}`} />
          )}
        </>
      )}
    </Box>
  );
};
