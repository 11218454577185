import { alpha, Stack, Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';
import { COLORS } from '../../constants';

export type BarType = 'gap' | 'video';

interface BarProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  width: string;
  type?: BarType;
  tooltipMessage?: string;
}

export const Bar: React.FC<PropsWithChildren<BarProps>> = ({
  onClick,
  width,
  type = 'video',
  children,
  tooltipMessage,
}) => {
  return (
    <Tooltip title={tooltipMessage} arrow placement="top">
      <Stack
        onClick={onClick}
        borderRadius="2px"
        bgcolor={type === 'gap' ? alpha(COLORS.PRIMARY, 0.4) : COLORS.BLUE_PRIMARY}
        height="16px"
        width={width}
        position="relative"
        sx={{
          '&:hover': { bgcolor: COLORS.SECONDARY },
          cursor: 'pointer',
          ...(type === 'gap' && {
            backgroundImage: `repeating-linear-gradient(
          -55deg,
          transparent,
          transparent 2px,
          ${alpha(COLORS.BLUE_PRIMARY, 0.2)} 2px,
          ${alpha(COLORS.BLUE_PRIMARY, 0.2)} 8px
          )`,
            '&:hover': { bgcolor: alpha(COLORS.SECONDARY, 0.2) },
            cursor: 'default',
          }),
        }}
      >
        {children}
      </Stack>
    </Tooltip>
  );
};
