import { Box, ListItem, ListItemButton, Typography } from '@mui/material';
import { activeStyles, baseStyles, selectedItemStyles } from '../../Sidebar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MutableRefObject, useEffect, useRef } from 'react';
import { PatientRO } from '../../../api/queries/patient/usePatients';

type PatientListItemProps = {
  patient: PatientRO;
  parentRef: MutableRefObject<HTMLUListElement | null>;
  selectedPatientId?: string;
  handleScrollbarPosition: (value: number) => void;
};

export const PatientListItem = ({
  patient,
  selectedPatientId,
  handleScrollbarPosition,
  parentRef,
}: PatientListItemProps) => {
  const { firstName, lastName, patientId, caseName, filesToReviewCount } = patient;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ref = useRef<HTMLLIElement | null>(null);

  const name = firstName && lastName ? `${firstName} ${lastName}` : caseName;

  useEffect(() => {
    if (selectedPatientId === patientId && parentRef?.current && ref?.current) {
      const rect = ref.current.getBoundingClientRect();
      const scrollTop = rect.top - rect.height + 10;
      handleScrollbarPosition(scrollTop);
    }
  }, [selectedPatientId, parentRef, patientId, handleScrollbarPosition]);

  return (
    <ListItem ref={ref} key={name} disablePadding data-id={patientId} sx={{ mt: 0.5 }}>
      <ListItemButton
        sx={selectedItemStyles}
        selected={selectedPatientId === patientId}
        onClick={() => navigate(`/dashboard/patient/${patientId}`)}
      >
        <Box sx={selectedPatientId === patientId ? { ...baseStyles, ...activeStyles } : baseStyles}>
          <Typography variant="body2">{name}</Typography>
          <Typography sx={{ fontSize: '0.75rem', opacity: 0.7 }}>
            {filesToReviewCount} {t('patient.videosToWatch')}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};
