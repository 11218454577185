import { CircularProgress, Stack, Typography } from '@mui/material';
import { PatientFileRO, usePatientFiles } from 'api/queries/patient/usePatientFiles';
import { CalendarSummary } from 'components/calendar/CalendarSummary';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DayPreview } from 'components/calendar/DayPreview';
import { selectedLanguage } from 'index';
import { startOfDay } from 'date-fns';
import { formatCalendarDate } from 'components/calendar/utils';
import { CalendarSummaryDialog } from 'components/calendar/summaryForm/CalendarSummaryDialog';
import { useDialogState } from 'hooks/useDialogState';
import { CalendarPageInfo } from 'components/calendar/CalendarPageInfo';
import { useGetSummaries } from 'api/queries/summary/useGetSummaries';
import { CaseSection } from 'components/calendar/CaseSection';
import { NAVBAR_HEIGHT } from 'components/Navbar/constants';

export interface GroupedFiles {
  date: string;
  files: PatientFileRO[];
  totalDuration: number;
}

export const groupByDate = (files: PatientFileRO[]): GroupedFiles[] => {
  const groupedFiles: Record<string, PatientFileRO[]> = {};

  files.forEach((file) => {
    const date = startOfDay(new Date(file.fileCreatedAt)).toString();

    if (!groupedFiles[date]) {
      groupedFiles[date] = [];
    }
    groupedFiles[date].push(file);
  });

  const value = Object.entries(groupedFiles).map(([date, files]) => ({
    date: formatCalendarDate(date, selectedLanguage),
    files,
    totalDuration: files.reduce((acc, file) => acc + file.lengthInSeconds, 0),
  }));

  value.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return value;
};

export const Calendar: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const patientId = params.patientId as string;

  const { data, isLoading } = usePatientFiles({ patientId });
  const { data: summaries } = useGetSummaries(patientId);
  const dialogState = useDialogState();

  const files = data?.files ?? [];

  const reviewedFilesIds = summaries?.summaries.flatMap((summary) => summary.fileIds) ?? [];
  const notReviewedFiles = files.filter((file) => !reviewedFilesIds.includes(file.fileId));

  const groupedNotReviewedFiles = groupByDate(notReviewedFiles);

  const spacingHeights = '60px';
  const calendarPageHeight = `calc(100vh - ${NAVBAR_HEIGHT} - ${spacingHeights})`;

  if (isLoading) {
    return (
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          mt: '140px',
        }}
      >
        <CircularProgress size={70} />
      </Stack>
    );
  }

  return (
    <Stack gap="16px" width="100%" mt="46px" height={calendarPageHeight} overflow="hidden">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{t('calendar.calendar')}</Typography>
        <CalendarPageInfo />
      </Stack>

      <Stack gap="16px" flex={1} overflow="auto">
        {!!groupedNotReviewedFiles.length && (
          <Stack gap="8px" mb="24px">
            {groupedNotReviewedFiles.map(({ date, files }) => (
              <DayPreview key={date} date={date} files={files} />
            ))}
          </Stack>
        )}

        {!!summaries?.summaries.length && (
          <>
            <Typography variant="h3">{t('dashboard.reviewed')}</Typography>
            <Stack gap="16px">
              {summaries?.summaries.map((summary, index) => (
                <CaseSection
                  key={summary.createdAt}
                  index={index + 1}
                  patientFiles={files}
                  summary={summary}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>

      <CalendarSummary
        files={notReviewedFiles}
        summaryButton={
          <CalendarSummaryDialog
            dayDetails={groupedNotReviewedFiles}
            dialogState={dialogState}
            patientId={patientId as string}
            key={String(dialogState.isOpen)}
          />
        }
      />
    </Stack>
  );
};
