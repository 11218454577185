import { Collapse, IconButton, Stack, Typography } from '@mui/material';
import { ArrowDownIcon } from 'assets/icons/ArrowDownIcon';
import { PropsWithChildren, useState } from 'react';
import { COLORS } from '../../../constants';

interface CollapseSectionProps {
  label: string;
}

export const CollapseSection = ({ label, children }: PropsWithChildren<CollapseSectionProps>) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Typography variant="P3_SB">{label}</Typography>
        <IconButton
          onClick={handleToggle}
          sx={{
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            'svg path': {
              fill: COLORS.BLACK_PRIMARY,
            },
          }}
        >
          <ArrowDownIcon />
        </IconButton>
      </Stack>

      <Collapse in={isExpanded} sx={{ px: 2 }}>
        {children}
      </Collapse>
    </Stack>
  );
};
