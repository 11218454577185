import React, { createContext, useCallback, useState } from 'react';

export enum MODAL_TYPE {
  PARENT_INVITATION = 'PARENT_INVITATION',
  SKIP_ALL_VIDEOS = 'SKIP_ALL_VIDEOS',
  ALL_VIDEOS_ANALYZED = 'ALL_VIDEOS_ANALYZED',
  REVIEW_ALREADY_CREATED = 'REVIEW_ALREADY_CREATED',
  CANCEL_REVIEW = 'CANCEL_REVIEW',
  DOCTOR_CREATED = 'DOCTOR_CREATED',
}

type ModalContextData = {
  openedModal: MODAL_TYPE | null;
  modalData: Record<string, any>;
  openModal: (modal: MODAL_TYPE, modalData?: Record<string, any>) => void;
  closeModal: () => void;
};

const contextData = {
  openedModal: null,
  modalData: {},
  closeModal: () => undefined,
  openModal: () => undefined,
};

export const ModalContext = createContext<ModalContextData>(contextData);

type ModalContextProviderProps = {
  children: React.ReactNode;
};

const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  const [modal, setModal] = useState<{ modal: MODAL_TYPE | null; modalData: Record<string, any> }>({
    modal: null,
    modalData: {},
  });

  const openModal = useCallback((modal: MODAL_TYPE, modalData?: Record<string, any>) => {
    setModal({ modalData: modalData || {}, modal });
  }, []);

  const closeModal = useCallback(() => {
    setModal({ modalData: {}, modal: null });
  }, []);

  return (
    <ModalContext.Provider
      value={{
        openedModal: modal.modal,
        modalData: modal.modalData,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
