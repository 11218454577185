import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SummaryFormInputs } from './CalendarSummaryDialog';

interface CalendarSummaryFormProps {
  form: UseFormReturn<SummaryFormInputs>;
}

export const CUSTOM_FIELD_VALUE = 'custom';

export const CalendarSummaryForm: FC<CalendarSummaryFormProps> = ({ form }) => {
  const { t } = useTranslation();
  const { control, watch } = form;

  const [description, recommendations] = watch(['description', 'recommendations']);

  const descriptionPresents = [
    { value: CUSTOM_FIELD_VALUE, label: t('calendar.summary.customComment') },
    {
      value:
        'Bei der Videoanalyse wurden keine für epileptische Anfälle charakteristischen Ereignisse festgestellt. Bitte beachten Sie, dass dies nicht bedeutet, dass Epilepsie ausgeschlossen ist. Einige Anfälle sind möglicherweise nicht sichtbar oder wurden nicht aufgezeichnet. Bitte besprechen Sie diese Ergebnisse zusammen mit Ihrer vollständigen Krankengeschichte und Ihren Symptomen mit Ihrem Arzt.',
      label: t('calendar.summary.negativeDetections'),
    },
    {
      value:
        'Bei der Videoanalyse wurden Ereignisse erkannt, die für epileptische Anfälle charakteristisch sein könnten. Bitte wenden Sie sich an Ihren Arzt, um diese Befunde zu überprüfen und die Notwendigkeit weiterer Untersuchungen und Behandlungen zu bestimmen. Bitte beachten Sie, dass diese Befunde keine Diagnose von Epilepsie oder einer anderen neurologischen Erkrankung darstellen.',
      label: t('calendar.summary.positiveDetections'),
    },
    {
      value:
        'Bei der Videoanalyse wurden abnormale Bewegungen festgestellt, die nicht spezifisch mit den Merkmalen eines epileptischen Anfalls übereinstimmen. Diese können auf eine neurologische Erkrankung hindeuten, müssen es aber nicht. Es ist wichtig, diese Befunde mit Ihrem Arzt zu besprechen, der sie im Zusammenhang mit Ihrem Gesundheitszustand interpretieren und gegebenenfalls weitere Untersuchungen oder Überwachungen empfehlen kann.',
      label: t('calendar.summary.otherAbnormal'),
    },
  ];

  const recommendationsPresents = [
    { value: CUSTOM_FIELD_VALUE, label: t('calendar.summary.customRecommendation') },
    {
      value:
        'In den analysierten Videos wurden keine für Anfälle charakteristischen Ereignisse festgestellt. Dies schließt jedoch eine Epilepsie nicht aus, da einige Anfälle möglicherweise nicht sichtbar sind oder nicht aufgezeichnet wurden. Wir empfehlen Ihnen, Ihren Arzt aufzusuchen und diese Ergebnisse zusammen mit Ihren Symptomen und Ihrer Krankengeschichte zu besprechen. Wenn Sie irgendwelche besorgniserregenden Symptome feststellen, sollten Sie sofort einen Arzt aufsuchen.',
      label: t('calendar.summary.negativeDetections'),
    },
    {
      value:
        'Die Videoanalyse deutet auf Ereignisse hin, bei denen es sich um Anfälle handeln könnte. Bitte beachten Sie, dass es sich hierbei nicht um eine endgültige Diagnose handelt, und wir empfehlen, dass Sie umgehend Ihren Arzt aufsuchen, um diese Ergebnisse zu überprüfen. Dieser kann weitere Untersuchungen oder Anpassungen Ihres Behandlungsplans empfehlen. Sollten Sie einen Notfall erleben, suchen Sie sofort einen Arzt auf.',
      label: t('calendar.summary.positiveDetections'),
    },
    {
      value:
        'Bei der Videoanalyse wurden keine Anfälle festgestellt, aber unsere Überprüfung ergab andere Bewegungsmuster, die abnormal sein könnten. Es ist wichtig, dass Sie diese Ergebnisse mit Ihrem Arzt besprechen. Er kann zusätzliche Überwachungsmaßnahmen oder andere Tests empfehlen, um Ihren Zustand besser zu verstehen. Wenn Sie irgendwelche Bedenken haben oder neue Symptome auftreten, sollten Sie einen Arzt aufsuchen.',
      label: t('calendar.summary.unclearDetections'),
    },
  ];

  return (
    <>
      <Stack gap="8px" mb="24px">
        <Typography variant="label">{t(`calendar.summary.results`)}</Typography>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Select {...field} size="small" displayEmpty>
              <MenuItem value="" disabled>
                {t('calendar.summary.commentPlaceholder')}
              </MenuItem>
              {descriptionPresents.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {description === CUSTOM_FIELD_VALUE && (
          <Controller
            control={control}
            name="customDescription"
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                multiline
                minRows={3}
                sx={{ '.MuiInputBase-root': { height: 'auto' } }}
              />
            )}
          />
        )}
        {description && description !== CUSTOM_FIELD_VALUE && (
          <TextField
            disabled
            value={description}
            size="small"
            multiline
            minRows={3}
            sx={{ '.MuiInputBase-root': { height: 'auto' } }}
          />
        )}
      </Stack>

      <Stack gap="8px">
        <Typography variant="label">{t('calendar.summary.recommendations')}</Typography>
        <Controller
          control={control}
          name="recommendations"
          render={({ field }) => (
            <Select {...field} size="small" displayEmpty>
              <MenuItem value="" disabled>
                {t('calendar.summary.recommendationsPlaceholder')}
              </MenuItem>
              {recommendationsPresents.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {recommendations === CUSTOM_FIELD_VALUE && (
          <Controller
            control={control}
            name="customRecommendation"
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                multiline
                minRows={3}
                sx={{ '.MuiInputBase-root': { height: 'auto' } }}
              />
            )}
          />
        )}
        {recommendations && recommendations !== CUSTOM_FIELD_VALUE && (
          <TextField
            disabled
            value={recommendations}
            size="small"
            multiline
            minRows={3}
            sx={{ '.MuiInputBase-root': { height: 'auto' } }}
          />
        )}
      </Stack>

      <FormControlLabel
        control={
          <Controller
            control={control}
            name="sendToML"
            render={({ field }) => <Checkbox {...field} checked={field.value} />}
          />
        }
        label={
          <Typography variant="label" mb={0}>
            {t('calendar.summary.sentToML')}
          </Typography>
        }
      />
    </>
  );
};
