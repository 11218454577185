import { IconButton, Modal as MuiModal, Stack, Typography } from '@mui/material';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { COLORS } from '../../constants';
import { useDialogState } from 'hooks/useDialogState';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface ModalProps {
  children: ReactNode;
  title: string;
  dialogState: ReturnType<typeof useDialogState>;
}

export const Modal: FC<ModalProps> = ({ children, title, dialogState }) => {
  return (
    <MuiModal
      onClose={dialogState.close}
      open={dialogState.isOpen}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          padding: '32px',
          borderRadius: '20px',
          background: COLORS.WHITE,
          maxWidth: '730px',
          width: '100%',
          minHeight: '70vh',
          maxHeight: '95vh',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb="32px">
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '500' }}>{title}</Typography>
          <IconButton onClick={dialogState.close}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {children}
      </Stack>
    </MuiModal>
  );
};

export const ModalFooter: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack direction="row" gap="12px" pt="32px" sx={{ '.MuiButton-root': { flex: 1 } }}>
      {children}
    </Stack>
  );
};
