import { FileMarker, MarkerType } from 'api/queries/patient/usePatientFiles';
import { useTranslation } from 'react-i18next';
import { parseSeizureEnum } from 'utils/seizureEnum';

export const useReviewLabels = (activeMarker: FileMarker | undefined | null) => {
  const { t } = useTranslation();

  const getLabel = (markerType: MarkerType) => {
    switch (markerType) {
      case 'USER_FEEDBACK':
        return `${t('patient.resultTitle')}`;
      case 'DOCTOR_CREATED':
        return t('patient.doctorReview');
      case 'ML_CREATED':
        return t('patient.machineLearningDetection');
      default:
        return null;
    }
  };

  const getSubTitle = (activeMarker: FileMarker) => {
    switch (activeMarker.type) {
      case 'USER_FEEDBACK':
        return `${t('patient.videoReview')} (${t('common.mandatory').toLowerCase()})`;
      case 'ML_CREATED':
        return `${t('patient.automatedClassification')} ${getMachineDetectionSeizure(
          activeMarker
        )}`;
      default:
        return null;
    }
  };

  const getMachineDetectionSeizure = (activeMarker: FileMarker) => {
    const machineLearningDetectionParsed = activeMarker?.mlReview?.seizureType
      ? parseSeizureEnum(activeMarker.mlReview.seizureType)
      : null;

    return machineLearningDetectionParsed
      ? machineLearningDetectionParsed
          .map((seizureType) => t(`reviewSeizure.${seizureType}`))
          .join(', ')
      : null;
  };

  const label = activeMarker?.type ? getLabel(activeMarker.type) : null;
  const subTitle = activeMarker ? getSubTitle(activeMarker) : null;

  return { label, subTitle };
};
