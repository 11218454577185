import { Typography } from '@mui/material';
import { useVideoPlayerContext } from '../../../contexts/VideoPlayerContext';
import { formatTime } from '../utilts';
import { COLORS } from '../../../constants';

export const VideoPlayerTime = () => {
  const { isPlayerReady, videoPlayerRef } = useVideoPlayerContext();

  const currentTime = videoPlayerRef.current ? videoPlayerRef.current.getCurrentTime() : 0;
  const duration = videoPlayerRef.current ? videoPlayerRef.current.getDuration() : 0;

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  return (
    <Typography
      sx={{
        color: COLORS.WHITE,
        position: 'absolute',
        width: 250,
        bottom: -22,
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
      }}
    >
      {isPlayerReady ? `${formatCurrentTime} / ${formatDuration}` : '0:00 / 0:00'}
    </Typography>
  );
};
