import Box from '@mui/material/Box';
import { PlayPauseButton } from '../PlayPauseButton';
import { FullscreenButton } from '../FullscreenButton';
import { VideoPlayerTime } from '../VideoPlayerTime';
import { VideoPlayerTimeline } from '../VideoPlayerTimeline';
import { SyntheticEvent } from 'react';
import { useVideoPlayerContext } from '../../../contexts/VideoPlayerContext';
import { Stack, SvgIcon } from '@mui/material';
import { SeekBackwardIcon } from 'assets/icons/SeekBackwardIcon';
import { SeekForwardIcon } from 'assets/icons/SeekForwardIcon';
import { SKIP_SECONDS } from '../Controls';

type FullscreenControlsProps = {
  playPauseHandler: () => void;
  seekHandler: (e: SyntheticEvent<Element, Event> | Event) => void;
  seekMouseUpHandler: (e: SyntheticEvent | Event, value: number | number[]) => void;
  onSeekMouseDownHandler: (e: SyntheticEvent | Event) => void;
  isFullscreenActive: boolean;
  handleToggleFullscreen: () => void;
};

export const FullscreenControls = ({
  seekHandler,
  seekMouseUpHandler,
  onSeekMouseDownHandler,
  isFullscreenActive,
  playPauseHandler,
  handleToggleFullscreen,
}: FullscreenControlsProps) => {
  const {
    videoState: { playing, played },
    videoPlayerRef,
  } = useVideoPlayerContext();

  const handleSeekForward = () => {
    if (!videoPlayerRef.current) {
      return;
    }

    const currentTime = videoPlayerRef.current.getCurrentTime();
    videoPlayerRef.current.seekTo(currentTime + SKIP_SECONDS);
  };

  const handleSeekBackward = () => {
    if (!videoPlayerRef.current) {
      return;
    }

    const currentTime = videoPlayerRef.current.getCurrentTime();
    videoPlayerRef.current.seekTo(currentTime - SKIP_SECONDS);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        px: '70px',
        py: '32px',
        background: 'rgba(14, 98, 150, 0.80)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <SeekButton onClick={handleSeekBackward} Icon={SeekBackwardIcon} />
          <Box position={'relative'}>
            <PlayPauseButton size="large" handlePlayPause={playPauseHandler} isPlaying={playing} />
            <VideoPlayerTime />
          </Box>
          <SeekButton onClick={handleSeekForward} Icon={SeekForwardIcon} />
        </Stack>
        <VideoPlayerTimeline
          color="secondary"
          played={played}
          onSeekMouseDownHandler={onSeekMouseDownHandler}
          seekHandler={seekHandler}
          seekMouseUpHandler={seekMouseUpHandler}
        />
        <Box
          sx={{
            width: '40px',
            height: '40px',
            svg: {
              width: '40px',
              height: '40px',
            },
          }}
        >
          <FullscreenButton
            isActive={isFullscreenActive}
            handleToggleFullscreen={handleToggleFullscreen}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface SeekButtonProps {
  onClick: () => void;
  Icon: () => JSX.Element;
}

const SeekButton = ({ onClick, Icon }: SeekButtonProps) => {
  return (
    <Box position={'relative'} display={'flex'} alignItems={'center'}>
      <SvgIcon
        viewBox="0 0 18 19"
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          aspectRatio: 1,
          width: '32px',
          height: '32px',
        }}
      >
        <Icon />
      </SvgIcon>
    </Box>
  );
};
