import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import { REVIEW_RESULT_STATUS } from 'api/queries/patient/constants';
import { FileMarker } from 'api/queries/patient/usePatientFiles';
import { MarkerContainer } from 'components/Timeline/MarkerLegend';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarkerFiltersInputs } from '.';
import { CollapseSection } from './CollapseSection';

type FiltersConfig = {
  name: keyof MarkerFiltersInputs;
  label: string;
  marker: FileMarker;
};

interface MarkerFiltersProps {
  form: UseFormReturn<MarkerFiltersInputs>;
}

export const MarkerFilters = ({ form }: MarkerFiltersProps) => {
  const { t } = useTranslation();
  const { control } = form;

  const filtersConfig: FiltersConfig[] = [
    { name: 'ml', label: 'markers.legend.ml', marker: { type: 'ML_CREATED' } as FileMarker },
    {
      name: 'detected',
      label: 'markers.legend.detected',
      marker: {
        doctorReview: { reviewType: 'DOCTOR', reviewResult: REVIEW_RESULT_STATUS.DETECTED },
      } as FileMarker,
    },
    {
      name: 'notDetected',
      label: 'markers.legend.notDetected',
      marker: {
        doctorReview: { reviewType: 'DOCTOR', reviewResult: REVIEW_RESULT_STATUS.NOT_DETECTED },
      } as FileMarker,
    },
    {
      name: 'feedback',
      label: 'markers.legend.feedback',
      marker: { type: 'USER_FEEDBACK' } as FileMarker,
    },
  ];

  return (
    <CollapseSection label={t('markers.description.filters')}>
      <FormGroup sx={{ alignContent: 'baseline' }}>
        {filtersConfig.map(({ name, label, marker }) => (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            key={name}
          >
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} sx={{ padding: '6px' }} />
                  )}
                />
              }
              label={t(label)}
            />
            <MarkerContainer marker={marker} />
          </Stack>
        ))}
      </FormGroup>
    </CollapseSection>
  );
};
