import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react';
import {
  PATIENT_LIST_ORDER,
  PATIENT_LIST_SORT,
  PatientsListRO,
  usePatients,
} from '../api/queries/patient/usePatients';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { Navigate, useLocation } from 'react-router-dom';
import { setLastDashboardFiltersUrl } from '../utils';
import { DEFAULT_URL } from '../constants';

type PatientListProviderProps = {
  children: ReactNode;
};

const defaultData = {
  content: [],
  totalCount: 0,
  query: {},
  setQuery: () => {},
};

export enum REVIEW_STATUS {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  REVIEWED = 'REVIEWED',
}

export const DEFAULT_ROWS_PER_PAGE = 10;

type PatientListContextRO = PatientsListRO & {
  query: {
    name?: string;
    to?: string;
    from?: string;
    reviewStatus?: string;
    onlyMyPatients?: boolean;
    rowsPerPage?: number;
    page?: number;
    sort?: PATIENT_LIST_SORT;
    order?: PATIENT_LIST_ORDER;
  };
  setQuery: (value: unknown) => void;
};

export const PatientListContext = createContext<PatientListContextRO>(defaultData);

export const usePatientListContext = () => {
  const patientList = useContext(PatientListContext);

  if (!patientList) {
    throw new Error('PatientListContext: No value provided');
  }

  return patientList;
};

const getOffset = (rowsPerPage?: number | null, page?: number | null): number | undefined => {
  if (!page) return undefined;
  return (rowsPerPage || DEFAULT_ROWS_PER_PAGE) * (page || 0);
};

export const PatientListProvider = ({ children }: PatientListProviderProps) => {
  const [query, setQueryParams] = useQueryParams({
    name: StringParam,
    to: StringParam,
    from: StringParam,
    reviewStatus: StringParam,
    onlyMyPatients: BooleanParam,
    rowsPerPage: NumberParam,
    offset: NumberParam,
    page: NumberParam,
    sort: StringParam,
    order: StringParam,
  });

  const setQuery = useCallback(
    (value: Partial<typeof query>) => {
      const newQuery = { ...value };
      Object.keys(newQuery).forEach((key) => {
        const fieldKey = key as keyof typeof value;

        if (newQuery[fieldKey] === '') {
          newQuery[fieldKey] = null;
        }
      });

      setQueryParams(newQuery);
    },

    [setQueryParams]
  );

  let { name, to, from, reviewStatus, rowsPerPage, page, sort, order } = query;

  const { data: patientListData, isPending } = usePatients({
    name: name || undefined,
    to: to || undefined,
    from: from || undefined,
    reviewStatus: (reviewStatus as REVIEW_STATUS) || undefined,
    onlyMyPatients: query.onlyMyPatients ?? true,
    limit: rowsPerPage || undefined,
    offset: getOffset(rowsPerPage, page) || undefined,
    sort: (sort as PATIENT_LIST_SORT) || PATIENT_LIST_SORT.DATE,
    order: (order as PATIENT_LIST_ORDER) || PATIENT_LIST_ORDER.DESC,
  });

  const prevRoute = useLocation();

  useEffect(() => {
    setLastDashboardFiltersUrl(prevRoute.pathname, prevRoute.search);
  }, [query]);

  useEffect(() => {
    // Reset page to 0 if the filters change and there's no content
    const resetPage = () => {
      if (isPending) {
        return;
      }

      if (page !== 0 && !patientListData?.content?.length) {
        setQuery({ page: 0 });
      }
    };

    resetPage();
  }, [page, patientListData?.content?.length, setQuery, isPending]);

  if (!prevRoute.search) {
    return <Navigate to={DEFAULT_URL.DASHBOARD} state={{ prevRoute }} replace />;
  }

  return (
    <PatientListContext.Provider
      value={
        {
          ...patientListData,
          query,
          setQuery,
        } as PatientListContextRO
      }
    >
      {children}
    </PatientListContext.Provider>
  );
};
