import { Box, ListItem, ListItemButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { activeStyles, baseStyles, selectedItemStyles } from '../index';
import { useTranslation } from 'react-i18next';
import { usePatientFiles } from 'api/queries/patient/usePatientFiles';

export const ReviewedPatientItemList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { patientId } = useParams();

  const { data: files } = usePatientFiles({ patientId: patientId as string });
  const name =
    files?.patientFirstName && files?.patientLastName
      ? `${files?.patientFirstName} ${files?.patientLastName}`
      : files?.caseName;

  return (
    <>
      {files && (
        <ListItem key={files.patientId} disablePadding sx={{ mt: 0.5 }}>
          <ListItemButton
            sx={selectedItemStyles}
            selected={patientId === files.patientId}
            onClick={() => navigate(`/dashboard/patient/${files.patientId}`)}
          >
            <Box
              sx={patientId === files.patientId ? { ...baseStyles, ...activeStyles } : baseStyles}
            >
              <Typography variant="body2">{name}</Typography>
              <Typography sx={{ fontSize: '0.75rem', opacity: 0.7 }}>
                0 {t('patient.videosToWatch')}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};
