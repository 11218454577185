import Box from '@mui/material/Box';
import { SearchInput } from '../SearchInput';
import { CustomDatePicker } from '../DatePicker';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, ChangeEvent, useState } from 'react';
import { PatientListContext, REVIEW_STATUS } from '../../contexts/PatientListProvider';
import dayjs from 'dayjs';
import { COLORS } from '../../constants';
import { PlusIcon } from 'assets/icons/PlusIcon';

export const FilterBar = () => {
  const { t } = useTranslation();
  const {
    query: { from, to, reviewStatus, onlyMyPatients },
    setQuery,
  } = useContext(PatientListContext);
  const [showFilters, setShowFilters] = useState(false);

  const showAllPatients = onlyMyPatients === undefined ? false : !onlyMyPatients;

  const handleToggleFilters = () => {
    setShowFilters((prevFilters) => !prevFilters);
  };

  const handleStatusClick = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;

    setQuery?.({ reviewStatus: value });
  };

  const handleToggleAllPatients = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery({ onlyMyPatients: !e.target.checked });
  };
  return (
    <Stack sx={{ gap: '8px 24px' }}>
      <Stack
        direction={'row'}
        sx={{
          gap: '8px 24px',
          flexWrap: 'wrap',
          alignItems: 'end',
        }}
      >
        <SearchInput />

        <Stack>
          <Typography variant="label" sx={{ marginLeft: '8px' }}>
            {t('dashboard.filters.organisation.label')}
          </Typography>
          <HighlightItems>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  inputProps={{ 'aria-label': t('dashboard.filters.organisation.description') }}
                  checked={showAllPatients}
                  onChange={handleToggleAllPatients}
                />
              }
              label={t('dashboard.filters.organisation.description')}
              labelPlacement="end"
            />
          </HighlightItems>
        </Stack>

        <Stack sx={{ marginRight: 'auto' }}>
          <Typography variant="label" sx={{ marginLeft: '8px' }}>
            {t('dashboard.filters.status.label')}
          </Typography>
          <HighlightItems>
            <RadioGroup
              aria-labelledby="status"
              name="status"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
              }}
              onChange={handleStatusClick}
            >
              <FormControlLabel
                value={REVIEW_STATUS.REVIEWED}
                checked={reviewStatus === REVIEW_STATUS.REVIEWED}
                control={<Radio />}
                label={t('status.REVIEWED')}
              />
              <FormControlLabel
                value={REVIEW_STATUS.AWAITING_REVIEW}
                checked={reviewStatus === REVIEW_STATUS.AWAITING_REVIEW}
                control={<Radio />}
                label={t('status.AWAITING_REVIEW')}
              />
              <FormControlLabel
                value={''}
                checked={!reviewStatus}
                control={<Radio />}
                label={t('status.all')}
              />
            </RadioGroup>
          </HighlightItems>
        </Stack>

        <FilterButton showFilters={showFilters} onClick={handleToggleFilters} />
      </Stack>
      {showFilters && (
        <Stack direction={'row'} sx={{ gap: '8px 24px', flexWrap: 'wrap' }}>
          <CustomDatePicker
            placeholder={t('datepicker.startDate')}
            label={t('datepicker.selectStartDate')}
            value={from ? dayjs(from).toDate() : null}
            maxDate={to ? dayjs(to).toDate() : null}
            onChange={(from) => setQuery({ from, page: 0 })}
          />
          <CustomDatePicker
            placeholder={t('datepicker.endDate')}
            label={t('datepicker.selectEndDate')}
            value={to ? dayjs(to).toDate() : null}
            minDate={from ? dayjs(from).toDate() : null}
            onChange={(to) => setQuery({ to, page: 0 })}
          />
        </Stack>
      )}
    </Stack>
  );
};

interface FilterButtonProps {
  showFilters: boolean;
  onClick: () => void;
}

const FilterButton = ({ showFilters, onClick }: FilterButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      aria-label="filter-button"
      aria-expanded={showFilters}
      variant="outlined"
      color="secondary"
      endIcon={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s',
            transform: showFilters ? 'rotate(45deg)' : undefined,
          }}
        >
          <PlusIcon />
        </Box>
      }
      onClick={onClick}
    >
      {showFilters
        ? t('dashboard.filters.dateFiltersButton.label.less')
        : t('dashboard.filters.dateFiltersButton.label.more')}
    </Button>
  );
};

const HighlightItems = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  background: COLORS.BLUE_SECOND,
  borderRadius: '8px',
  height: '48px',
  padding: '0 8px',
}));
