import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Sidebar } from '../../Sidebar';
import { PatientSidebarProvider } from 'contexts/PatientSidebarProvider';

export const LayoutSidebar = () => {
  return (
    <Box sx={{ height: '100%', display: 'flex', mx: '48px' }}>
      <PatientSidebarProvider>
        <Sidebar />
        <Outlet />
      </PatientSidebarProvider>
    </Box>
  );
};
