import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { dateToQueryParam } from 'utils/dateUtils';

export const formatCalendarDate = (date: string, locale: string) => {
  const dateObject = new Date(date);

  return dateObject.toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
  });
};

export const getVideoPageLink = (video: PatientFileRO, patientId: string) => {
  return `/dashboard/patient/${patientId}/${dateToQueryParam(video.fileCreatedAt)}/file/${
    video.fileId
  }`;
};

export const getReviewedByDoctorMarks = (files: PatientFileRO[]) => {
  return files.flatMap((file) =>
    file.markers.filter(
      (marker) =>
        marker.doctorReview?.reviewType === 'DOCTOR' &&
        marker.doctorReview.reviewResult !== 'SKIPPED'
    )
  );
};

export const getDetectedByDoctorMarks = (files: PatientFileRO[]) => {
  return getReviewedByDoctorMarks(files).filter(
    (marker) => marker.doctorReview?.reviewResult === 'DETECTED'
  );
};
