import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PatientFileRO, useGetPatientFile } from '../api/queries/patient/useGetFile';
import ReactPlayer from 'react-player/lazy';

type VideoPlayerProviderProps = {
  children: ReactNode;
};

type PatientFileData = Partial<Omit<PatientFileRO, 'fileUrl'>>;
type VideoPlayerContextProps = {
  selectedFileId?: string;
  fileUrl: string | null;
  videoPlayerRef: React.RefObject<ReactPlayer>;
  isPlayerReady: boolean;
  setIsPlayerReady: (isReady: boolean) => void;
  videoState: VideoStateProps;
  setVideoState: React.Dispatch<React.SetStateAction<VideoStateProps>>;
} & PatientFileData;

export const VideoPlayerContext = createContext<VideoPlayerContextProps | null>(null);

export const useVideoPlayerContext = () => {
  const videoPlayer = useContext(VideoPlayerContext);

  if (!videoPlayer) {
    throw new Error('VideoPlayerContext: No value provided');
  }

  return videoPlayer;
};

const DEFAULT_STATE = {
  playing: false,
  muted: false,
  volume: 1,
  played: 0,
  playedSeconds: 0,
  seeking: false,
  isFullscreen: false,
  buffer: false,
};

export type VideoStateProps = {
  playing: boolean;
  muted: boolean;
  volume: number;
  played: number;
  playedSeconds: number;
  seeking: boolean;
  isFullscreen: boolean;
  buffer: boolean;
};

export const VideoPlayerProvider = ({ children }: VideoPlayerProviderProps) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
  const [videoState, setVideoState] = useState<VideoStateProps>(DEFAULT_STATE);
  const { patientId, selectedFileId } = useParams();
  const { data } = useGetPatientFile({
    patientId: patientId || '',
    fileId: selectedFileId || '',
  });

  useEffect(() => {
    /**
     * The fileUrl changes every data refetch, thus rerendering the video player, including playback time etc.
     * This way we keep the url the same and we can keep the video state
     */
    const updateUrl = () => {
      if (data && !fileUrl) {
        setFileUrl(data.fileUrl);
      }
    };

    updateUrl();
  }, [data, fileUrl]);

  return (
    <VideoPlayerContext.Provider
      value={{
        selectedFileId,
        ...data,
        fileUrl,
        isPlayerReady,
        setIsPlayerReady,
        videoState,
        setVideoState,
        videoPlayerRef,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};
