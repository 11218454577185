import { Stack, Tooltip, Typography } from '@mui/material';
import { COLORS } from '../../constants';
import { FC, ReactNode } from 'react';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { useTranslation } from 'react-i18next';
import { getDetectedByDoctorMarks, getReviewedByDoctorMarks } from './utils';

interface CalendarSummaryProps {
  files: PatientFileRO[];
  summaryButton: ReactNode;
}

export const CalendarSummary: FC<CalendarSummaryProps> = ({ files, summaryButton }) => {
  const { t } = useTranslation();

  const reviewedByDoctorMarks = getReviewedByDoctorMarks(files);
  const reviewedByDoctorMarksAmount = reviewedByDoctorMarks.length;
  const detectedMarks = getDetectedByDoctorMarks(files);
  const allMarksAmount = files.flatMap((file) => file.markers).length;

  const reviewedMarksPercentage = Math.round((reviewedByDoctorMarksAmount / allMarksAmount) * 100);

  return (
    <Stack p="24px" gap="12px">
      <Typography variant="h1">{t('calendar.summary.title')}</Typography>
      <Stack direction="row" gap="40px">
        <Stack position="relative" width="fit-content" alignItems="center" justifyContent="center">
          <MarkersChart reviewedPercentage={reviewedMarksPercentage} />
          <Stack textAlign="center" position="absolute">
            <Typography variant="h1">{allMarksAmount}</Typography>
            <Typography variant="P3_R">{t('calendar.summary.totalMarks')}</Typography>
          </Stack>
        </Stack>

        <Stack justifyContent="space-between">
          <ChartLegend
            remainingMarks={allMarksAmount - reviewedByDoctorMarksAmount}
            reviewedByDoctor={reviewedByDoctorMarksAmount}
            detectedByDoctor={detectedMarks.length}
          />

          <Stack mb="12px" direction="row" gap="8px" alignItems="center">
            {summaryButton}
            <Tooltip title={t('calendar.summary.unreviewedVideosTooltip')} arrow placement="right">
              <Stack>
                <InfoIcon />
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const MarkersChart: FC<{ reviewedPercentage: number }> = ({ reviewedPercentage }) => {
  const circleProps = {
    cx: 21,
    cy: 21,
    r: '15.91549430918954',
    fill: 'transparent',
  };

  return (
    <svg width="170px" height="170px" viewBox="0 0 42 42">
      <circle {...circleProps} stroke={COLORS.SECONDARY} strokeWidth="4" />
      {reviewedPercentage && (
        <circle
          {...circleProps}
          stroke={COLORS.PRIMARY}
          strokeWidth="4.3"
          strokeDasharray={`${reviewedPercentage} ${100 - reviewedPercentage}`}
          strokeDashoffset="25"
          strokeLinecap="round"
        />
      )}
    </svg>
  );
};

interface ChartLegendItem {
  reviewedByDoctor: number;
  detectedByDoctor: number;
  remainingMarks: number;
}

const ChartLegend: FC<ChartLegendItem> = ({
  reviewedByDoctor,
  detectedByDoctor,
  remainingMarks,
}) => {
  const { t } = useTranslation();

  const legend = [
    {
      color: COLORS.PRIMARY,
      name: t('calendar.reviewedByDoctor'),
      value: `${reviewedByDoctor} ${t('calendar.seizuresDetected', {
        detected: detectedByDoctor,
      })}`,
    },
    { color: COLORS.SECONDARY, name: t('calendar.remainingMarkers'), value: remainingMarks },
  ];

  return (
    <Stack gap="16px" mt="10px">
      {legend.map((item, index) => (
        <Stack gap="8px" direction="row" alignItems="center" key={index}>
          <Stack width="16px" height="16px" bgcolor={item.color} borderRadius="50%" />
          <Typography variant="P4_R" color={COLORS.GRAY_PRIMARY}>
            {item.name}:{' '}
            <Typography color={COLORS.BLACK_PRIMARY} variant="P4_R">
              {item.value}
            </Typography>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
