import React, { useState, useRef, useCallback } from 'react';
import { Box, TextField } from '@mui/material';
import { CODE_LENGTH } from 'api/queries/auth/useValidateCode';

interface CodeInputProps {
  onChange: (code: string) => void;
  isError?: boolean;
}

const EMPTY_CODE = Array(CODE_LENGTH).fill('');

const CodeInput: React.FC<CodeInputProps> = ({ onChange, isError }) => {
  const [code, setCode] = useState<string[]>(EMPTY_CODE);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleCodeChange = useCallback(
    (newCode: string[]) => {
      setCode(newCode);
      onChange(newCode.join(''));
    },
    [onChange]
  );

  const handleFocus = (index: number) => (e: React.FocusEvent<HTMLInputElement>) => {
    if (index === 0 && code.join('').length > 1) {
      handleCodeChange(EMPTY_CODE);
      return;
    }

    e.target.select();
  };

  const handlePaste = useCallback(
    (index: number) => (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('text');
      const newCode = [...code];

      const maxLength = Math.min(pastedText.length, CODE_LENGTH - index);

      for (let i = 0; i < maxLength; i++) {
        newCode[index + i] = pastedText[i];
      }

      handleCodeChange(newCode);
      inputRefs.current[maxLength - 1]?.focus();
    },
    [code, handleCodeChange, inputRefs]
  );

  const handleFieldChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const newCode = [...code];
      newCode[index] = value;
      handleCodeChange(newCode);

      if (value && index < CODE_LENGTH - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    },
    [code, handleCodeChange]
  );

  const handleBackspacePress = (index: number) => {
    if (!code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.key === 'Backspace') {
      handleBackspacePress(index);
    }
  };

  return (
    <Box display="flex" gap={2} mx={1}>
      {[...Array(6)].map((_, index) => (
        <TextField
          key={index}
          sx={{
            '.MuiInputBase-root': {
              height: 56,
              paddingRight: 0,
            },
            ' & input': {
              textAlign: 'center',
            },
          }}
          autoFocus={index === 0}
          onKeyDown={handleKeyDown(index)}
          onFocus={handleFocus(index)}
          onPaste={handlePaste(index)}
          onChange={handleFieldChange(index)}
          value={code[index]}
          inputProps={{ maxLength: 1 }}
          error={isError}
          inputRef={(ref) => (inputRefs.current[index] = ref)}
        />
      ))}
    </Box>
  );
};

export default CodeInput;
