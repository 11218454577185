import { useMutation } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';

interface ValidateCodePayload {
  code: string;
}

export const CODE_LENGTH = 6;

export const useValidateCode = () => {
  return useMutation({
    mutationFn: validateCode,
  });
};

type ValidateCodeRO = {
  organisationId: string;
  organisationName: string;
};

const validateCode = (data: ValidateCodePayload): Promise<ValidateCodeRO> => {
  return baseClient({
    url: '/invitation/doctor/validate',
    method: 'POST',
    data,
  });
};
