import { add, differenceInSeconds, endOfDay, format, startOfDay } from 'date-fns';
import { BarType } from '../Bar';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';

export const calculateOverlapPercentage = ({
  timeline,
  point,
}: {
  timeline: { start: number; end: number };
  point: { start: number; end: number };
}) => {
  const overlapStart = Math.max(timeline.start, point.start);
  const overlapEnd = Math.min(timeline.end, point.end);
  const overlapLength = overlapEnd - overlapStart;
  const timelineLength = timeline.end - timeline.start;
  const overlapStartPercentage = ((overlapStart - timeline.start) / timelineLength) * 100;
  const overlapEndPercentage = ((overlapEnd - timeline.start) / timelineLength) * 100;

  if (overlapLength <= 0) {
    return false;
  }

  return {
    overlapStartPercentage,
    overlapEndPercentage,
  };
};

export const findClosest = (number1: number, number2: number) => {
  const firstNumber = { number: number1, closestValues: [number1 % 1, 1 - (number1 % 1)] };
  const secondNumber = { number: number2, closestValues: [number2 % 1, 1 - (number2 % 1)] };
  const theClosestNumber = Math.min(...firstNumber.closestValues, ...secondNumber.closestValues);

  if (firstNumber.closestValues.includes(theClosestNumber)) {
    return firstNumber.number;
  }

  return secondNumber.number;
};

export const getStepSize = (totalFilesDuration: number) => {
  if (!totalFilesDuration) {
    return { stepSize: 0, numberOfSteps: 0 };
  }

  const POSSIBLE_NUMBER_OF_STEPS = [4, 5, 6, 7];
  const stepSizesPerStep = POSSIBLE_NUMBER_OF_STEPS.reduce(
    (acc, numberOfSteps) => {
      const stepSize = totalFilesDuration / numberOfSteps;
      acc.push({ stepSize, numberOfSteps });

      return acc;
    },
    [] as { numberOfSteps: number; stepSize: number }[]
  );
  /**
   * We are calculating the remainder number that's left after using modulo to find out which number is the closest to it's absolute number.
   * E.g. if we got 1.25, 5.25, 9.95 the closest number to have the best steps would be 9.95, because it's the closest to absolute number (10)
   */
  const mostSuitableStepSize = stepSizesPerStep.reduce(
    (acc, { numberOfSteps, stepSize }) => {
      if (acc.stepSize === 0) {
        acc = { numberOfSteps, stepSize };
      }

      const closestNumber = findClosest(acc.stepSize, stepSize);
      if (closestNumber === stepSize) {
        acc = { numberOfSteps, stepSize };
      }

      return acc;
    },
    { numberOfSteps: 0, stepSize: 0 }
  );

  return mostSuitableStepSize;
};

export const formatTime = (seconds: number, totalFilesDuration: number) => {
  const isLongerThanHour = totalFilesDuration / 60 / 60 > 1;
  const date = new Date();

  // The date is returned with the current timezone (+01:00), so we need to reset it to 00:00
  // otherwise the timestamp starts from 1 hour
  date.setHours(0, 0, seconds);

  if (isLongerThanHour) {
    return format(date, 'HH:mm') + 'h';
  }

  return format(date, 'mm:ss') + 'm';
};

const getTotalSecondsInDay = (date: Date | string) => {
  const currentDate = new Date(date);
  const startOfDayDate = startOfDay(currentDate);

  return differenceInSeconds(currentDate, startOfDayDate);
};

type Bar = {
  type: BarType;
  time: number;
  startTime: string;
  endTime: string;
  file?: PatientFileRO;
};

export const sortByDate = (a: PatientFileRO, b: PatientFileRO) => {
  /**
   * This may be unnecessary, because now im supporting a case where fileCreatedAt can be the same,
   * but at the point of writing this code the dates were duplicated, so I wanted to cover that
   */
  const compareCreatedAt =
    new Date(a.fileCreatedAt).getTime() - new Date(b.fileCreatedAt).getTime();
  const compareUploadedAt =
    new Date(a.fileUploadedAt).getTime() - new Date(b.fileUploadedAt).getTime();

  return compareCreatedAt || compareUploadedAt;
};

export const getBars = (files: PatientFileRO[]) => {
  const bars: Bar[] = [];

  const copiedFiles = [...files];
  copiedFiles.sort(sortByDate);
  copiedFiles.forEach((file, i, self) => {
    const { fileCreatedAt, lengthInSeconds } = file;
    const date = new Date(fileCreatedAt);

    if (i === 0) {
      const gapLength = getTotalSecondsInDay(fileCreatedAt);
      if (gapLength > 0) {
        bars.push(
          createBar({
            time: gapLength,
            type: 'gap',
            startTime: startOfDay(Date.now()),
            endTime: date,
          })
        );
      }
      bars.push(
        createBar({
          time: lengthInSeconds,
          type: 'video',
          startTime: date,
          endTime: add(date, { seconds: lengthInSeconds }),
          file,
        })
      );
    }

    if (i > 0) {
      const previousDate = new Date(self[i - 1].fileCreatedAt);
      const endOfPreviousDate = add(previousDate, { seconds: self[i - 1].lengthInSeconds });

      const gapLength = differenceInSeconds(date, endOfPreviousDate);
      if (gapLength > 0) {
        bars.push(
          createBar({
            time: gapLength,
            type: 'gap',
            startTime: endOfPreviousDate,
            endTime: date,
          })
        );
      }
      bars.push(
        createBar({
          time: lengthInSeconds,
          type: 'video',
          startTime: date,
          endTime: add(date, { seconds: lengthInSeconds }),
          file,
        })
      );
    }
    if (i === self.length - 1) {
      const gapLength = differenceInSeconds(endOfDay(date), date);
      const endOfCurrentDate = add(date, { seconds: lengthInSeconds });

      bars.push(
        createBar({
          time: gapLength,
          type: 'gap',
          startTime: endOfCurrentDate,
          endTime: endOfDay(date),
        })
      );
    }
  });

  const modifiedBars = getModifiedBars(bars);
  const totalDuration = bars.reduce((acc, block) => acc + block.time, 0);

  return {
    bars: modifiedBars,
    totalDuration,
  };
};

const DEFAULT_BAR_FORMAT = 'HH:mm';
const MIN_BAR_TIME = 900; // This seem like a minimum, acceptable time (in seconds) for a bar to have a reasonable width
const BAR_MULTIPLIER = 3.5; // We have min. bar time, however, this allows us to modify the width of the bar in a more intuitive way

interface CreateBarPayload {
  type: BarType;
  time: number;
  startTime: Date;
  endTime: Date;
  file?: PatientFileRO;
}

const createBar = ({ type, time, startTime, endTime, file }: CreateBarPayload): Bar => {
  return {
    type,
    time,
    startTime: format(startTime, DEFAULT_BAR_FORMAT),
    endTime: format(endTime, DEFAULT_BAR_FORMAT),
    file,
  };
};

const getModifiedBars = (bars: Bar[]) => {
  return bars
    .map((block) => {
      if (block.time < MIN_BAR_TIME && block.type === 'video') {
        return { ...block, time: MIN_BAR_TIME };
      }

      return block;
    })
    .map((block) => {
      if (block.type === 'video') {
        return { ...block, time: block.time * BAR_MULTIPLIER };
      }

      return block;
    })
    .filter((block) => {
      if (block.time < MIN_BAR_TIME && block.type === 'gap') {
        return false;
      }

      return true;
    });
};
