import { VideoPlayer } from '../VideoPlayer';
import Box from '@mui/material/Box';
import { VideoPlayerProvider } from '../../contexts/VideoPlayerContext';
import { Controls } from 'components/VideoPlayer/Controls';
import { useParams } from 'react-router-dom';

export const PatientVideo = () => {
  /**
   * The key prevents keeping the state of the zoom in/out slider
   */
  const { selectedFileId } = useParams();

  return (
    <Box
      sx={{
        width: '100%',
        pb: '24px',
        overflow: 'hidden',
      }}
    >
      <VideoPlayerProvider>
        <VideoPlayer />
        <Controls key={selectedFileId} />
      </VideoPlayerProvider>
    </Box>
  );
};
