import { Stack } from '@mui/material';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { Marker, MARKER_END_SECOND_OFFSET } from './Marker';
import { FC, useMemo } from 'react';
import { calculateOverlapPercentage, getBars } from './utils';
import { Bar } from './Bar';
import { useNavigate, useParams } from 'react-router-dom';
import { getVideoPageLink } from 'components/calendar/utils';

interface TimelineProps {
  files: PatientFileRO[];
}

export const Timeline: FC<TimelineProps> = ({ files }) => {
  const { patientId } = useParams<{ patientId: string }>();
  const navigate = useNavigate();

  const getFileWidth = (time: number, totalDuration: number) => {
    return (time / totalDuration) * 100 + '%';
  };

  const navigateToVideo = (file: PatientFileRO) => {
    const link = getVideoPageLink(file, patientId as string);
    navigate(link);
  };

  const getMarkers = useMemo(
    () => (fileId?: string) => {
      const file = files.find((file) => file.fileId === fileId);
      if (!file) {
        return [];
      }

      return file.markers.map((marker) => {
        const isMarkerVisible = calculateOverlapPercentage({
          timeline: { start: 0, end: file.lengthInSeconds },
          point: {
            start: marker.startSecond,
            end: marker.endSecond + MARKER_END_SECOND_OFFSET,
          },
        });

        if (!isMarkerVisible) {
          return null;
        }

        return (
          <Marker
            marker={marker}
            startTime={0}
            fileDuration={file.lengthInSeconds}
            key={marker.id}
          />
        );
      });
    },
    [files]
  );

  const { bars, totalDuration } = getBars(files);

  return (
    <Stack direction="row" gap="6px">
      {bars.map((bar, i) => {
        const { type, startTime, endTime, time, file } = bar;

        return (
          <Bar
            key={i}
            tooltipMessage={`${startTime} - ${endTime}`}
            type={type}
            width={getFileWidth(time, totalDuration)}
            onClick={(e) => {
              if (!file) {
                return;
              }

              e.stopPropagation();
              navigateToVideo(file);
            }}
          >
            {getMarkers(file?.fileId)}
          </Bar>
        );
      })}
    </Stack>
  );
};
