import { Outlet } from 'react-router-dom';
import { SessionProvider } from '../../../contexts/SessionProvider';
import { Navbar } from '../../Navbar';
import Box from '@mui/material/Box';
import ModalContextProvider from '../../../contexts/ModalContext';
import { GlobalModal } from '../../GlobalModal/GlobalModal';
import { Toast } from '../../Toast/Toast';

export const Layout = () => {
  return (
    <SessionProvider>
      <ModalContextProvider>
        <Box sx={{ flexGrow: 1, mt: '106px' }}>
          <Navbar />
          <Outlet />
          <Toast />
        </Box>
        <GlobalModal />
      </ModalContextProvider>
    </SessionProvider>
  );
};
