import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { COLORS } from '../constants';
import { ArrowLeft } from '../assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { FC, ReactNode, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../contexts/SessionProvider';
import { useProfileSettings } from '../api/queries/auth/useProfileSettings';
import { QUERY_KEYS } from '../api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { usePasswordReset } from '../api/queries/auth/usePasswordReset';
import { useGetConfig } from '../api/queries/auth/useGetConfig';
import { getLastDashboardFiltersUrl } from '../utils';
import toast from 'react-hot-toast';
import { useDialogState } from 'hooks/useDialogState';
import { ChangeEmailModal } from 'components/ChangeEmailModal';

export const Settings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { firstName, lastName, email, organisation } = useCurrentUserContext();
  const { mutate: profileSettingsMutation } = useProfileSettings();
  const { data: config } = useGetConfig();
  const { link } = usePasswordReset({ keycloakUrl: config?.keycloakUrl });

  const [firstNameValue, setFirstNameValue] = useState(firstName);
  const [lastNameValue, setLastNameValue] = useState(lastName);
  const [emailValue, setEmailValue] = useState(email);

  useEffect(() => {
    setFirstNameValue(firstName);
    setLastNameValue(lastName);
    setEmailValue(email);
  }, [firstName, lastName, email]);

  const submitField = (field: 'firstName' | 'lastName') => {
    if (!firstName || !lastName) return;

    profileSettingsMutation(
      {
        firstName: field === 'firstName' ? firstNameValue : firstName,
        lastName: field === 'lastName' ? lastNameValue : lastName,
      },
      {
        onSuccess: () => {
          toast.success(t('settings.successful'));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SESSION] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errCode = err?.response?.data?.code;
            toast.error(errCode ? t(`error.${errCode}`) : err.message);
          }
        },
      }
    );
  };

  const dialogState = useDialogState();

  const isEmailValid = () => /^\S+@\S+\.\S+$/.test(emailValue);

  return (
    <Box
      component="form"
      sx={{
        m: '8px 48px 24px 48px',
        '.MuiTextField-root': {
          maxWidth: '720px',
          width: '100%',
          '.MuiInputBase-root': { bgcolor: COLORS.WHITE },
          fieldset: { border: 'unset' },
        },
        '.MuiButtonBase-root': {
          width: '270px',
        },
      }}
    >
      <Box sx={{ display: 'flex', py: 1.5, alignItems: 'center' }}>
        <Box
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => navigate(getLastDashboardFiltersUrl())}
        >
          <ArrowLeft />
        </Box>
        <Typography
          sx={{
            fontSize: '1.25rem',
            ml: 1,
            color: COLORS.BLUE_THIRD,
            fontWeight: 500,
            cursor: 'pointer',
          }}
          onClick={() => navigate(getLastDashboardFiltersUrl())}
        >
          {t('common.settings')}
        </Typography>
      </Box>
      <Stack gap="32px" mt="20px">
        <SettingsSection title={t('settings.generalTitle')}>
          <Stack gap="8px">
            <Typography variant="label">{t(`common.firstName`)}</Typography>
            <Stack direction="row" gap="24px">
              <TextField
                id="firstName"
                size="small"
                value={firstNameValue}
                onChange={(e) => setFirstNameValue(e.target.value)}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => submitField('firstName')}
                disabled={!firstNameValue || firstName === firstNameValue}
              >
                {t('settings.saveChanges')}
              </Button>
            </Stack>
          </Stack>

          <Stack gap="8px">
            <Typography variant="label">{t(`common.lastName`)}</Typography>
            <Stack direction="row" gap="24px">
              <TextField
                id="lastName"
                size="small"
                value={lastNameValue}
                onChange={(e) => setLastNameValue(e.target.value)}
              />
              <Button
                variant="contained"
                color="secondary"
                disabled={!lastNameValue || lastName === lastNameValue}
                onClick={() => submitField('lastName')}
              >
                {t('settings.saveChanges')}
              </Button>
            </Stack>
          </Stack>

          <Stack gap="8px">
            <Typography variant="label">{t(`common.organisation`)}</Typography>
            <Typography
              variant="body2"
              sx={{
                padding: '8px 14px',
              }}
            >
              {organisation.name}
            </Typography>
          </Stack>
        </SettingsSection>

        <SettingsSection title={t('settings.authenticationTitle')}>
          <Stack gap="8px">
            <Typography variant="label">{t(`common.email`)}</Typography>
            <Stack direction="row" gap="24px">
              <TextField
                id="email"
                size="small"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />
              <Button
                variant="contained"
                color="secondary"
                disabled={!emailValue || !isEmailValid() || email === emailValue}
                onClick={dialogState.open}
              >
                {t('settings.saveChanges')}
              </Button>
            </Stack>
          </Stack>

          <Stack gap="8px">
            <Typography variant="label">{t(`login.password`)}</Typography>
            <Stack direction="row" gap="24px">
              <TextField id={'common'} size="small" value="********" type="password" disabled />
              <Button variant="contained" color="secondary" href={link}>
                {t('settings.changePassword')}
              </Button>
            </Stack>
          </Stack>
        </SettingsSection>
      </Stack>

      <ChangeEmailModal
        dialogState={dialogState}
        newEmail={emailValue}
        key={String(dialogState.isOpen)}
      />
    </Box>
  );
};

interface SettingsSectionProps {
  children: ReactNode;
  title: string;
}

export const SettingsSection: FC<SettingsSectionProps> = ({ title, children }) => {
  return (
    <Stack gap="32px" bgcolor={COLORS.BLUE_SECOND} p="24px" borderRadius="12px">
      <Typography variant="h5">{title}</Typography>
      {children}
    </Stack>
  );
};
