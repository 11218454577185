import { Timeline } from 'components/Timeline';
import { t } from 'i18next';
import { PatientFileRO } from 'api/queries/patient/usePatientFiles';
import { CollapseSection } from './CollapseSection';

interface GeneralSectionProps {
  currentDateFiles?: PatientFileRO[];
}

export const GeneralSection = ({ currentDateFiles }: GeneralSectionProps) => {
  if (!currentDateFiles) {
    return null;
  }

  return (
    <CollapseSection label={t('patient.sessionPreview')}>
      <Timeline files={currentDateFiles} />
    </CollapseSection>
  );
};
