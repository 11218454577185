import { ParentInvitationModal } from 'components/GlobalModal/modals/ParentInvitationModal';
import { MODAL_TYPE } from 'contexts/ModalContext';
import { useModal } from 'hooks/useModal';
import { Box, Modal, styled } from '@mui/material';
import { SkipAllVideosModal } from './modals/SkipAllVideosModal';
import { AllVideosAnalyzedModal } from './modals/AllVideosAnalyzedModal';
import { ReviewAlreadyCreatedModal } from './modals/ReviewAlreadyCreatedModal';
import { CancelReviewModal } from './modals/CancelReviewModal';
import { DoctorCreatedModal } from './modals/DoctorCreatedModal';

const modals = {
  [MODAL_TYPE.PARENT_INVITATION]: <ParentInvitationModal />,
  [MODAL_TYPE.SKIP_ALL_VIDEOS]: <SkipAllVideosModal />,
  [MODAL_TYPE.ALL_VIDEOS_ANALYZED]: <AllVideosAnalyzedModal />,
  [MODAL_TYPE.REVIEW_ALREADY_CREATED]: <ReviewAlreadyCreatedModal />,
  [MODAL_TYPE.CANCEL_REVIEW]: <CancelReviewModal />,
  [MODAL_TYPE.DOCTOR_CREATED]: <DoctorCreatedModal />,
};

export const GlobalModal = () => {
  const { openedModal, closeModal } = useModal();

  return (
    <ModalStyled open={!!openedModal} onClose={closeModal}>
      <>{openedModal && modals[openedModal]}</>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ModalContent = styled(Box)(() => ({
  position: 'relative',
  width: '518px',
  borderRadius: '16px',
  border: '1px solid var(--colors-general-white-01, #FFF)',
  background: 'linear-gradient(121deg, #10699A 2%, #044E89 40.76%, #034A87 61.37%)',
  margin: 'auto',
  padding: '32px',
}));
