import { Box, Typography, Alert, Button } from '@mui/material';
import { useValidateCode, CODE_LENGTH } from 'api/queries/auth/useValidateCode';
import CodeInput from 'components/CodeInput';
import { COLORS } from '../../constants';
import { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

interface CodeEnterFormProps {
  onConfirm: (code: string, name: string) => void;
}

export const CodeEnterForm = ({ onConfirm }: CodeEnterFormProps) => {
  const { t } = useTranslation();

  const { mutate, isPending } = useValidateCode();

  const [error, setError] = useState('');
  const [code, setCode] = useState('');

  const ERRORS = {
    INVITATION_CODE_NOT_FOUND: t('error.INVITATION_CODE_NOT_FOUND'),
    INVITATION_CODE_ALREADY_USED: t('error.INVITATION_CODE_ALREADY_USED'),
  };

  const isCodeValid = code.length === CODE_LENGTH;

  const handleCodeChange = (newCode: string) => {
    setCode(newCode);
    if (error) {
      setError('');
    }
  };

  const getError = (errorCode: string) => {
    const error = ERRORS[errorCode as keyof typeof ERRORS];

    if (!error) {
      return t('error.UNKNOWN_ERROR');
    }

    return error;
  };
  const handleSubmit = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    mutate(
      { code },
      {
        onError: (error) => {
          if (error instanceof AxiosError) {
            const errCode = error?.response?.data?.code;
            const formError = getError(errCode);
            setError(formError);
          }
        },
        onSuccess: ({ organisationName }) => {
          onConfirm(code, organisationName);
        },
      }
    );
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Typography
        variant="body2"
        sx={{
          color: COLORS.BLACK_PRIMARY,
          mb: 2,
        }}
      >
        {t('register.enterInvitationCode')}
      </Typography>
      {error && (
        <Alert
          severity="error"
          icon={false}
          sx={{
            mb: 2,
            background: COLORS.BLACK_PRIMARY,
            color: '#fff',
            borderRadius: '8px',
          }}
        >
          <Typography variant="body2">{error}</Typography>
        </Alert>
      )}
      <CodeInput onChange={handleCodeChange} isError={!!error} />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        sx={{
          mt: 4,
          mb: 2,
        }}
        size="large"
        disabled={!isCodeValid || !!error || isPending}
      >
        {t('register.confirmCode')}
      </Button>
    </Box>
  );
};
